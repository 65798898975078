/*Disabled  Ionic CSS Variables so that the not affect our theming*/

:root {
  --ion-background-color: #F1F2F3;
  --ion-toolbar-background: #FFFFFF;
  --ion-tab-bar-background: #FFFFFF;
}

.pageWhite {
  --ion-background-color: #FFFFFF;
}

.ion-split-pane-menu-shrink {
  --side-width: 60px;
  --side-min-width: 60px;
  --side-max-width: 60px;
}

.ion-split-pane-menu {
  --side-width: 180px;
  --side-min-width: 180px;
  --side-max-width: 180px;
  --border: 0;
}